import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmedGeocodeResponse } from '@/modules/geocoder/components/confirm-geocode-response-dialog/confirm-geocode-response';
import {
  AddressToGeocodeResponseDialogComponent,
  AddressToGeocodeResponseDialogData,
} from '@/modules/geocoder/components/address-to-geocode-response-dialog/address-to-geocode-response-dialog.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-address-input',
  templateUrl: 'address-input.component.html',
  styleUrls: ['address-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: AddressInputComponent },
  ],
  host: {
    '[class.app-address-input-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class AddressInputComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<ConfirmedGeocodeResponse>,
    OnDestroy
{
  static nextId = 0;

  parts: UntypedFormGroup;
  confirmedGeocodeResponse: ConfirmedGeocodeResponse;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'app-address-input';
  id = `app-address-input-${AddressInputComponent.nextId++}`;
  describedBy = '';
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    if (!this.confirmedGeocodeResponse) {
      return true;
    }
    return !this.confirmedGeocodeResponse.displayAddress;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('show-clear-btn') showClearBtn: boolean;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): ConfirmedGeocodeResponse | null {
    if (
      this.confirmedGeocodeResponse &&
      this.confirmedGeocodeResponse.geocode
    ) {
      return this.confirmedGeocodeResponse;
    }
    return null;
  }

  set value(confirmedGeocodeResponse: ConfirmedGeocodeResponse | null) {
    if (
      !confirmedGeocodeResponse ||
      !this.confirmedGeocodeResponse ||
      !this.confirmedGeocodeResponse?.isEqual(confirmedGeocodeResponse)
    ) {
      this.confirmedGeocodeResponse = confirmedGeocodeResponse;

      this.parts.setValue({
        addressDisplay: confirmedGeocodeResponse?.displayAddress || '',
      });
      this.onChange(confirmedGeocodeResponse);
      this.stateChanges.next();
    }
  }

  constructor(
    formBuilder: UntypedFormBuilder,
    private _focusMonitor: FocusMonitor,
    private _dialog: MatDialog,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.parts = formBuilder.group({
      addressDisplay: ['', Validators.required],
    });

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    this.onTouched();
    if ((event.target as Element).tagName.toLowerCase() === 'mat-icon') {
      this._clear();
    } else {
      this._handleInput();
    }
  }

  writeValue(confirmedGeocodeResponse: ConfirmedGeocodeResponse | null): void {
    this.value = confirmedGeocodeResponse;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get errorState() {
    return (
      this.ngControl.errors !== null &&
      (!!this.ngControl.touched || !!this.ngControl.dirty)
    );
  }

  _clear(): void {
    this.value = null;
  }

  _handleInput(): void {
    const data = new AddressToGeocodeResponseDialogData(
      this.value ? this.value.addressInfo : null
    );
    const dialogRef = this._dialog.open(
      AddressToGeocodeResponseDialogComponent,
      {
        width: '600px',
        data,
        disableClose: true,
      }
    );

    this.focused = true;

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(value => {
        this.focused = false;
        if (!value) {
          return;
        }
        this.value = value;
        this.onSelect.emit(this.value);
      });
  }
}
