import { CompanyInfo } from '@/data/company/models/company';
import {
  HIRLRaterUserInfo,
  HIRLUserProfile,
} from '@/data/customer-hirl/models';
import { Geocode, GeocodeBrokeredResponse } from '@/data/geocoder/models';
import { AccreditationInfo } from '@/data/user-management/models';

export class UserInfo {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  company: number;
  company_info?: CompanyInfo;
  is_company_admin: boolean;
  is_superuser: boolean;
  is_active: boolean;
  is_approved: boolean;
}
export class User {
  full_name?: string;
  id?: number;
  username?: string;
  first_name?: string;
  last_name?: string;
  last_login?: string;
  email?: string;
  title?: string;
  department?: string;
  work_phone?: string;
  cell_phone?: string;
  fax_number?: string;
  alt_phone?: string;
  is_active?: boolean;
  is_superuser?: boolean;
  is_approved?: boolean;
  show_beta?: boolean;
  is_admin?: boolean;
  is_public?: boolean;
  company?: number;
  company_info?: CompanyInfo;
  timezone_preference?: string;
  is_company_admin?: boolean;
  hirlrateruser?: number;
  hirlrateruser_info?: HIRLRaterUserInfo;
  hirluserprofile?: HIRLUserProfile;

  mailing_geocode?: number;
  mailing_geocode_info?: Geocode;
  mailing_geocode_response?: number;
  mailing_geocode_response_info?: GeocodeBrokeredResponse;

  shipping_geocode?: number;
  shipping_geocode_info?: Geocode;
  shipping_geocode_response?: number;
  shipping_geocode_response_info?: GeocodeBrokeredResponse;

  signature_image?: string;

  rater_id?: number;
  rater_roles?: number[];

  resnet_username?: string;
  resnet_password?: string;
}

export class HIRLFindVerifierProvidedServiceInfo {
  id?: number;
  slug?: string;
  name?: string;
  order?: number;
  last_update?: string;
  created_at?: string;
}

export class HIRLFindVerifierVerifierAgreementInfo {
  id?: number;
  state?: string;
  us_states?: string[];
  provided_services?: HIRLFindVerifierProvidedServiceInfo[];
}

export class HIRLFindVerifier {
  id?: number;
  username?: string;
  first_name?: string;
  last_name?: string;
  company_info?: CompanyInfo;
  accreditations_info?: AccreditationInfo[];
  work_phone?: string;
  cell_phone?: string;
  customer_hirl_enrolled_verifier_agreements_info?: HIRLFindVerifierVerifierAgreementInfo[];
}

export class HIRLFindGreenVerifier {
  id?: number;
  username?: string;
  first_name?: string;
  last_name?: string;
  company_info?: CompanyInfo;
  work_phone?: string;
  cell_phone?: string;
  customer_hirl_enrolled_verifier_agreements_info?: HIRLFindVerifierVerifierAgreementInfo[];
  sf_remodel_projects?: number;
  mf_remodel_projects?: number;
  total_remodel_projects?: number;
}

export class CustomerHirlCOIDashboard extends User {
  general_liability?: string;
  professional_liability?: string;
  automobile_liability?: string;
  umbrella_liability?: string;
  workers_compensation?: string;
}
