<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <h3>
                        <span *ngIf="!projectsIsLoading">{{ projectsCount | number }}</span>
                        <fa-icon [icon]="['fas', 'spinner']" [spin]="true" *ngIf="projectsIsLoading"></fa-icon>
                        NGBS Project Registrations
                    </h3>
                </div>
                <div class="col-6 text-right">
                    <button [matMenuTriggerFor]="addProjectMenu"
                            mat-raised-button color="accent">Add New Project</button>
                    <mat-menu #addProjectMenu="matMenu">
                        <button mat-menu-item [routerLink]="['/', 'hi', 'project_registrations', 'add', 'single-family']">Single Family</button>
                        <button mat-menu-item [routerLink]="['/', 'hi', 'project_registrations', 'add', 'multi-family']">Multifamily</button>
                        <button mat-menu-item [routerLink]="['/', 'hi', 'project_registrations', 'add', 'land-development']">Land Development</button>
                    </mat-menu>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <hr>
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="filterFromGroup" class="card">
                                <h5 class="card-header">Filters <a href="#" class="small" (click)="resetFilters($event);">Reset filters</a></h5>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Search</mat-label>
                                                <app-search-input formControlName="search" placeholder="Type to search something"></app-search-input>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Project Type</mat-label>
                                                <mat-select formControlName="registration__project_type">
                                                    <mat-option [value]="''">
                                                        Any
                                                    </mat-option>
                                                    <mat-option *ngFor="let item of typeLabelMapping | keyvalue " [value]="item.key">
                                                        {{ item.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Status</mat-label>
                                                <mat-select formControlName="registration__state">
                                                    <mat-option [value]="''">
                                                        Any
                                                    </mat-option>
                                                    <mat-option *ngFor="let item of stateLabelMapping | keyvalue " [value]="item.key">
                                                        {{ item.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Is Appeals Project?</mat-label>
                                                <mat-select formControlName="appeals_project">
                                                    <mat-option [value]="''">
                                                        Any
                                                    </mat-option>
                                                    <mat-option *ngFor="let item of appealsLabelMapping | keyvalue " [value]="item.key">
                                                        {{ item.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Accessory Structure</mat-label>
                                                <mat-select formControlName="is_accessory_structure">
                                                    <mat-option [value]="''">
                                                        Any
                                                    </mat-option>
                                                    <mat-option [value]="'true'">
                                                        Yes
                                                    </mat-option>
                                                    <mat-option [value]="'false'">
                                                        No
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Accessory Dwelling Unit</mat-label>
                                                <mat-select formControlName="is_accessory_dwelling_unit">
                                                    <mat-option [value]="''">
                                                        Any
                                                    </mat-option>
                                                    <mat-option [value]="'true'">
                                                        Yes
                                                    </mat-option>
                                                    <mat-option [value]="'false'">
                                                        No
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Is Commercial Space</mat-label>
                                                <mat-select formControlName="commercial_space_type">
                                                    <mat-option [value]="''">
                                                        Any
                                                    </mat-option>
                                                    <mat-option *ngFor="let item of projectCommercialSpaceType | keyvalue " [value]="item.key">
                                                        {{ item.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Is Build To Rent</mat-label>
                                                <mat-select formControlName="registration__is_build_to_rent">
                                                    <mat-option [value]="''">
                                                        Any
                                                    </mat-option>
                                                    <mat-option [value]="'true'">
                                                        Yes
                                                    </mat-option>
                                                    <mat-option [value]="'false'">
                                                        No
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Registered By User</mat-label>
                                                <app-generic-model-autocomplete
                                                        formControlName="registration__registration_user_info"
                                                        modelClassName="user"
                                                        [initialValueId]="storedParams.registration__registration_user"
                                                        [filterParams]="registrationUserControlRequestParams"
                                                        [panelWidth]="480"
                                                ></app-generic-model-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Created At Start</mat-label>
                                                <input matInput [matDatepicker]="datepicker_created_at__gte" formControlName="created_at__gte">
                                                <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('created_at__gte').setValue(null)">
                                                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                                </mat-datepicker-toggle>
                                                <mat-datepicker-toggle matSuffix [for]="datepicker_created_at__gte"></mat-datepicker-toggle>
                                                <mat-datepicker #datepicker_created_at__gte></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Created At End</mat-label>
                                                <input matInput [matDatepicker]="datepicker_created_at__lte" formControlName="created_at__lte">
                                                <mat-datepicker-toggle matSuffix (click)="filterFromGroup.get('created_at__lte').setValue(null)">
                                                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                                </mat-datepicker-toggle>
                                                <mat-datepicker-toggle matSuffix [for]="datepicker_created_at__lte"></mat-datepicker-toggle>
                                                <mat-datepicker #datepicker_created_at__lte></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="row" *ngIf="projectsIsLoading">
                        <div class="col-12">
                            <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
                        </div>
                    </div>
                    <table mat-table
                        [dataSource]="projects"
                        matSort
                        [matSortActive]="storedParams.toTableSort().active"
                        [matSortDirection]="storedParams.toTableSort().direction"
                        (matSortChange)="onSort($event)">
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Project ID</th>
                            <td mat-cell *matCellDef="let row" [ngStyle]="{ 'vertical-align': 'top' }">
                                <a [routerLink]="['/', 'hi', 'project_registrations', row.registration]" target="_blank">
                                    {{ row.id }}
                                </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="registration__project_name">Name</th>
                            <td mat-cell *matCellDef="let row" [ngStyle]="{ 'vertical-align': 'top' }">
                                <ng-container *ngIf="row.registration_info.project_name">
                                    <span *ngIf="!row.registration_info.subdivision">
                                        {{ row.registration_info.project_name }}
                                    </span>
                                    <a *ngIf="row.registration_info.subdivision"
                                       [routerLink]="['/', 'subdivision', row.registration_info.subdivision]" target="_blank">
                                        {{ row.registration_info.project_name }}
                                    </a>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="address">
                            <th mat-header-cell *matHeaderCellDef>Address</th>
                            <td mat-cell *matCellDef="let row" [ngStyle]="{ 'vertical-align': 'top' }"> 
                                <ng-container *ngIf="!row.home_status">
                                    <app-address-display [geocode]="row.home_address_geocode_info"
                                                         [geocodeBrokeredResponse]="row.home_address_geocode_response_info">
                                    </app-address-display>
                                    <span *ngIf="row.lot_number">Lot #: {{ row.lot_number }}</span>
                                </ng-container>
                                <a *ngIf="row.home_status"
                                   [routerLink]="['/', 'home', row.home_status_info.home]" target="_blank">
                                    <app-address-display [geocode]="row.home_address_geocode_info"
                                                         [geocodeBrokeredResponse]="row.home_address_geocode_response_info">
                                    </app-address-display>
                                    <span *ngIf="row.lot_number">Lot #: {{ row.lot_number }}</span>
                                </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="program">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="registration__eep_program__name">Program</th>
                            <td mat-cell *matCellDef="let row" [ngStyle]="{ 'vertical-align': 'top' }">
                                <a *ngIf="row.registration_info.eep_program_info.name"
                                   [routerLink]="['/', 'eep_program', row.registration_info.eep_program]" target="_blank">
                                    {{ row.registration_info.eep_program_info.name }}
                                </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef>Type</th>
                            <td mat-cell *matCellDef="let row" [ngStyle]="{ 'vertical-align': 'top' }">
                                <app-hirl-project-type [project]="row"></app-hirl-project-type>
                                <div class="text-muted" *ngIf="row.registration_info?.project_type === HIRLProjectRegistrationType.multiFamily && !row?.commercial_space_type && !row?.is_accessory_structure">
                                  <i>Story Count: {{ row.story_count }}</i>
                                </div>
                                <div class="text-muted" *ngIf="row.registration_info?.project_type === HIRLProjectRegistrationType.multiFamily && !row?.commercial_space_type && !row?.is_accessory_structure">
                                  <i>Unit Count: {{ row.number_of_units }}</i>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="registration__state">Status</th>
                            <td mat-cell *matCellDef="let row" [ngStyle]="{ 'vertical-align': 'top' }">
                                <a [routerLink]="['/', 'hi', 'project_registrations', row.registration]" target="_blank">
                                    <app-hirl-project-registration-state [projectRegistration]="row.registration_info"></app-hirl-project-registration-state>
                                </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="registeredBy">
                            <th mat-header-cell *matHeaderCellDef>Registered by</th>
                            <td mat-cell *matCellDef="let row" [ngStyle]="{ 'vertical-align': 'top' }">
                                <app-user-display [user]="row.registration_info?.registration_user_info" displayType="onlyNameAsLink"></app-user-display>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <div class="d-flex flex-row">
                        <div class="align-self-center">
                            <button mat-raised-button (click)="exportToCSV($event)" [disabled]="projectsIsLoading" class="mr-2" matTooltip="Export Data to CSV file">CSV</button>
                            <button mat-raised-button (click)="exportToExcel($event)" [disabled]="projectsIsLoading" matTooltip="Export Data to Excel file">Excel</button>
                        </div>
                        <div class="d-flex flex-fill justify-content-end">
                            <mat-paginator [length]="projectsCount"
                                           [pageIndex]="$any(storedParams.page) - 1"
                                           [pageSize]="storedParams.page_size"
                                           (page)="onPaginateChange($event)"
                                           aria-label="Select page">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
