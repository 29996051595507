import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@/state/reducers';
import { map } from 'rxjs/operators';
import { HIRLProjectRegistrationPermissionService } from '@/modules/customer-hirl/services/hirl-project-registration-permission-service';
import { toggleLoading } from '@/state/actions/app.actions';

@Injectable({ providedIn: 'root' })
export class CustomerHIRLEditHIRLProjectRegistrationGuard
  implements CanActivate
{
  constructor(
    public router: Router,
    public store: Store<AppState>,
    public hirlProjectRegistrationPermissionService: HIRLProjectRegistrationPermissionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.dispatch(toggleLoading({ payload: true }));
    return this.hirlProjectRegistrationPermissionService
      .canEdit(route.params.projectRegistrationId)
      .pipe(
        map(objectPermissionResponse => {
          console.log(objectPermissionResponse);
          this.store.dispatch(toggleLoading({ payload: false }));
          if (objectPermissionResponse.hasPermission) {
            return true;
          }
          this.router.navigate(['403'], {
            queryParams: { returnUrl: state.url.split('?')[0] },
          });
          return false;
        })
      );
  }
}
