export class HIRLUserProfile {
  id?: number;
  is_qa_designee?: boolean;

  active_customer_hirl_verifier_agreements_count?: number;
  customer_hirl_project_accreditations_count?: number;
  active_accreditations_count?: number;
  sf_remodel_projects_count?: number;
  mf_remodel_projects_count?: number;
}
